<template>
  <el-card id="encryptSwitch">
    <header>
      <h2>
        <div style="display: flex; align-items: center">
          号码隐私开关
        <el-tooltip
          class="item"
          effect="dark"
          content="点击查看详情说明"
          placement="top"
        >
          <img
            v-if="!showTitleText"
            src="@/assets/images/what.png"
            alt=""
            style="width: 30px; height: 30px; cursor: pointer"
            @click="showTitleText = true"
          />
        </el-tooltip>
        </div>
        <div v-if="showTitleText">
          (为了数据的安全性，可设置号码隐藏。只需把坐席账号添加到隐藏板块，坐席账号则无法看到完整的客户号码（只能看号码前三后四数，中间4位用*表示）；反之添加到显示板块，则恢复显示)
        </div>
        
      </h2>
      <!-- <hr> -->
      <hr>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>部门选择</span>
            <el-select
              v-model="departId"
              placeholder="请选择部门"
              size="mini"
              @change="departmentSel"
            >
              <el-option
                v-for="item in departList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置选项</el-button
          >
        </div>
      </div>
    </header>
    <main>
      <div class="staff-title" style="line-height: 30px">人员筛选</div>
      <div class="roll">
        <!-- 白名单 -->
        <div class="white-wrap">
          <div class="hbtn">
            <h3>显示</h3>
            <el-button
              type="primary"
              @click="blackBtn"
              :disabled="allcityOptionsB.length == 0 ? true : false"
              >点击隐藏</el-button
            >
          </div>

          <div class="staff">
            <div class="staff-list">
              <el-checkbox
                :indeterminate="isIndeterminateStstusB"
                v-model="statusAllB"
                @change="handlestatusAllChangeB"
                style="line-height: 30px"
                >全选</el-checkbox
              >
              <el-checkbox-group
                v-model="statusB"
                @change="handlestatusChangeB"
                style="line-height: 30px"
              >
                <el-checkbox
                  v-for="city in citiesB"
                  :label="city"
                  :key="city.id"
                  >{{ city.realname }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>

        <!-- 黑名单 -->
        <div class="white-wrap">
          <div class="hbtn">
            <h3>隐藏</h3>
            <el-button
              type="primary"
              @click="blackBtnBlack"
              :disabled="allcityOptionsBlack.length == 0 ? true : false"
              >点击显示</el-button
            >
          </div>

          <div class="staff">
            <div class="staff-list">
              <el-checkbox
                :indeterminate="isIndeterminateStstusBlack"
                v-model="statusAllBlack"
                @change="handlestatusAllChangeBlack"
                style="line-height: 30px"
                >全选</el-checkbox
              >
              <el-checkbox-group
                v-model="statusBlack"
                @change="handlestatusChangeBlack"
                style="line-height: 30px"
              >
                <el-checkbox
                  v-for="city in citiesBlack"
                  :label="city"
                  :key="city.id"
                  >{{ city.realname }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </main>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import axios from "axios";

export default {
  data() {
    return {
      departId:null,
      showTitleText:false,
      hideNumArr: [],
      id: "",
      causeData: "",
      departList: [], // 部门选择
      statusAllB: false, // 白名单
      statusAllBlack: false, // 黑名单
      statusB: [], // 白名单
      statusBlack: [], // 黑名单
      allcityOptionsB: [],
      allcityOptionsBlack: [],
      citiesB: [],
      citiesBlack: [], // 黑名单
      isIndeterminateStstusB: true, // 白名单
      isIndeterminateStstusBlack: true, // 黑名单
    };
  },
  created() {
    this.$nextTick(async function () {
      let res1 = await this.getHideNum();
      let res2 = await this.getList();
      let res3 = await this.getDepartList();
    });
  },
  computed: {},
  inject: ["reload"],
  methods: {
    // 隐藏号码
    async blackBtnBlack() {
      let roleName = localStorage.getItem("roleName");
      let parentId = "";
      if (roleName == "企业") {
        parentId = localStorage.getItem("id");
      } else {
        parentId = localStorage.getItem("parentId");
      }
      //  else if (roleName == "个人") {
      // }
      this.realname = localStorage.getItem("realname");
      console.log("选中的黑名单", this.statusBlack);
      // let id = JSON.parse(localStorage.getItem("id"));
      let userStatus = []; // 传递的参数
      // 选中的元素
      let selectArr = this.statusBlack.map((v) => {
        return v.id;
      });
      console.log("选中的元素", selectArr);

      this.allcityOptionsBlack.forEach((v) => {
        if (!selectArr.includes(v.id)) {
          userStatus.push(v.id);
        }
      });
      console.log("传递的参数id", this.id);

      let params = {
        listId: userStatus,
        parentId,
      };

      let res = await this.updateUserStatus(params);
      let res1 = await this.reload();
    },
    getHideNum() {
      return axios
        .post("/phone-encryption/find-encryption", {})
        .then((res) => {
          this.hideNumArr = res.data.data.split(",");
          console.log("登录时查询隐藏的号码", this.hideNumArr);
        })
        .catch((err) => {});
    },
    // 显示号码
    async blackBtn() {
      let roleName = localStorage.getItem("roleName");
      let parentId = "";
      if (roleName == "企业") {
        parentId = localStorage.getItem("id");
      } else {
        parentId = localStorage.getItem("parentId");
      }
      console.log("选中的白名单", this.statusB);

      // console.log("选中的黑名单", this.citiesB);
      // let id = JSON.parse(localStorage.getItem("id"));
      let userStatus = this.statusB.map((v) => {
        return v.id;
      });
      // console.log("名单", this.statusB);
      this.allcityOptionsBlack.forEach((v) => {
        userStatus.push(v.id);
      });
      console.log("传递的参数listid", userStatus);

      let params = {
        listId: userStatus,
        parentId,
      };

      let res1 = await this.updateUserStatus(params);
      let res2 = await this.reload();
    },
    // 添加或删除黑名单
    updateUserStatus(params) {
      return axios.post("/phone-encryption/add", params).then((res) => {
        let data = res.data.data;
        // console.log("选中的黑名单", this.citiesB);
        Message.success(res.data.message);
        // 刷新数据
        this.getList();
      });
    },
    // 黑名单全选事件
    handlestatusAllChangeBlack(valB) {
      this.statusBlack = valB ? this.allcityOptionsBlack : [];
      this.isIndeterminateStstusBlack = false;
    },
    // 黑名单单选事件
    handlestatusChangeBlack(valueB) {
      let checkedCount = valueB.length;
      this.statusAllBlack = checkedCount === this.citiesBlack.length;
      this.isIndeterminateStstusBlack =
        checkedCount > 0 && checkedCount < this.citiesBlack.length;
    },
    // 多选 白
    handlestatusAllChangeB(valB) {
      this.statusB = valB ? this.allcityOptionsB : [];
      this.isIndeterminateStstusB = false;
    },
    handlestatusChangeB(valueB) {
      let checkedCount = valueB.length;
      this.statusAllB = checkedCount === this.citiesB.length;
      this.isIndeterminateStstusB =
        checkedCount > 0 && checkedCount < this.citiesB.length;
    },
    // 重置
    reset() {
      this.reload();
    },

    // 人员 数据
    async getList() {
      let res = await axios.post("/phone/find-user").then((res) => {
        console.log(">>>>《《《>>>>", res.data.data);
        let data = res.data.data;
        return data.user;
      });
      let parentId = JSON.parse(localStorage.getItem("id"));
      let res1 = this.hideNumArr;
      // 筛选白名单
      // citiesB : 白名单  citiesBlack:黑名单
      this.citiesB = [];
      this.citiesBlack = [];
      this.statusB = [];
      this.statusBlack = [];
      this.allcityOptionsBlack = [];
      this.allcityOptionsB = [];
      this.statusAllB = false;
      this.statusAllBlack = false;
      res.forEach((v, i) => {
        // if (delIndex) {
        //   delIndex.forEach((value) => {
        //     // 将黑名单的数据取出来
        //     if (value * 1 == v.id) {
        //       this.citiesBlack.push(v);
        //       console.log("delIndex", delIndex);
        //     }
        //   });
        // }
        if (res1.includes(v.id + "")) {
          this.allcityOptionsBlack.push(v);
        } else {
          this.allcityOptionsB.push(v);
        }
      });
      this.citiesBlack = this.allcityOptionsBlack;
      this.citiesB = this.allcityOptionsB;
      console.log("白名单", this.citiesB);
      console.log("黑名单", this.citiesBlack);
    },
    // 部门
    getDepartList() {
      let param = {
        page: 1,
        size: 30,
        topId: localStorage.getItem("id"),
      };
      return axios.post("/sys_depart/list", param).then((res) => {
        console.log(">>>>>>>>", res.data.data);
        let data = res.data.data;
        // console.log("选择部门", res.data.data);
        this.departList = data.map((v) => {
          return { id: v.id, name: v.departName };
        });
        console.log("所有名单数据", this.departList);
      });
    },

    // 部门选择
    departmentSel() {
      let param = {
        departId: this.departId,
        username: null,
      };
      return axios.post("/user/list", param).then((res) => {
        // console.log(res);
        if (res.data.statusCode == "00000") {
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>______", res.data.data);
          let data = res.data.data;
          if (data) {
            this.allcityOptionsB = data.map((v, i) => {
              return { realname: v.realname, id: v.user_id };
            });
            Message.success(res.data.message);
          } else {
            Message.warning("查询数据为空");
          }
          this.citiesB = this.allcityOptionsB;
        } else {
          Message.warning(res.data.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#encryptSwitch {
  height: 100%;
  overflow-y: scroll;

  header {
    margin-bottom: 20px;
    h1 {
      margin-bottom: 20px;
      font-weight: normal;
      > div {
        font-size: 18px;
        color: #606369;
        margin-top: 10px;
      }
    }
    .search {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      //   flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          margin-right: 10px;
          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    min-height: 500px;

    .hbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .white-wrap,
    .black-wrap {
      border: 3px solid #f6f6f6;
      width: 48%;
      height: 100%;
      min-height: 300px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 20px;
    }
    h3 {
      font-weight: normal;
      color: #3278FE;
    }
    .roll {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .staff-data {
      margin-left: 41px;
    }
    div /deep/.causeShow {
      margin-left: 485px;
    }
    #evictionPolicy /deep/ .el-date-editor {
      width: 75% !important;
    }

    .title {
      font-weight: normal;
      margin-bottom: 50px;
    }
    .btns {
      min-width: 15vw;
    }
    .btn {
      width: 90px;
      height: 40px;
    }
    .wrap,
    .status {
      margin-bottom: 30px;
    }
    .time-call {
      max-width: 85vw;
      justify-content: space-around;
    }
    .time-call > div {
      align-items: center;
    }
    .time-call > div > div {
      margin-right: 5px;
    }
    .status {
      align-items: center;
    }
    .status-title {
      width: 10%;
    }
    .status-list > label {
      align-items: center;
      margin-right: 30px;
    }
    .staff-title {
      width: 10%;
    }
    .staff-list {
      flex: 1;
    }
    .staff-list-data {
      flex-wrap: wrap;
    }
    .wrap,
    .time-call,
    .time-call > div,
    .status,
    .status-list,
    .staff,
    .staff-list-data {
      display: flex;
    }
  }
}
</style>